import { Link } from '@tanstack/react-router';
import { Button, Col, Row, Tabs, Typography } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { FlexBox } from 'components/Helpers';
import { useOrganization } from 'hooks/auth/useOrganization';
import { organizationAnalyticsInsights } from 'organizationMetadataKeys';
import { useMemo } from 'react';
import { FiExternalLink, FiPieChart } from 'react-icons/fi';

interface Insight {
  id: string;
  accessToken: string;
}

type InsightsPerProduct = Record<string, Record<string, Insight>>;

function getInsightProductName(product: string) {
  switch (product) {
    case 'voize-app-senior-care':
      return 'Altenpflege App';
    case 'voize-sdk':
      return 'Altenpflege SDK';
    default:
      return product;
  }
}

const maxPanels = 12;

const InsightsView: React.FC = () => {
  const organization = useOrganization();

  const insightsPerProduct = useMemo<InsightsPerProduct>(() => {
    const metadataValue = organization?.metadata[organizationAnalyticsInsights];
    if (metadataValue) {
      return JSON.parse(metadataValue);
    }
    return {};
  }, [organization]);

  const filteredInsightsPerProduct = useMemo(() => {
    return Object.entries(insightsPerProduct).filter(
      ([, insights]) => Object.values(insights).length > 0,
    );
  }, [insightsPerProduct]);

  if (filteredInsightsPerProduct.length === 0) return null;

  return (
    <FlexBox direction="column" withgap>
      <Typography.Title level={4}>
        <FlexBox alignitems="center" withgap>
          <FiPieChart />
          <span>Analyse</span>
        </FlexBox>
      </Typography.Title>
      {filteredInsightsPerProduct.length > 1 ? (
        <Tabs>
          {filteredInsightsPerProduct.map(([product, insights]) => {
            return (
              <TabPane tab={getInsightProductName(product)} key={product}>
                <ProductInsights insights={insights} />
              </TabPane>
            );
          })}
        </Tabs>
      ) : (
        <ProductInsights insights={filteredInsightsPerProduct[0][1]} />
      )}
    </FlexBox>
  );
};

const ProductInsights: React.FC<{
  insights: Record<string, Insight>;
}> = ({ insights }) => {
  return (
    <FlexBox direction="column" withgap gap={20}>
      <Row gutter={[20, 20]}>
        {Object.entries(insights).map(([insightKey, insight]) => (
          <Col key={insightKey} className="group relative">
            <Link
              to={`https://eu.posthog.com/shared/${insight.accessToken}?refresh=true&whitelabel`}
              target="_blank"
              className="absolute transition right-5 top-3 opacity-0 group-hover:opacity-100"
            >
              <Button
                icon={<FiExternalLink size={18} color="hsl(200, 10%, 50%)" />}
                type="text"
                size="large"
              />
            </Link>
            <iframe
              src={`https://eu.posthog.com/embedded/${insight.accessToken}?refresh=true&whitelabel`}
              style={{
                width: 650,
                height: 300,
                backgroundColor: 'hsl(200, 10%, 90%)',
                borderRadius: 5,
              }}
              loading="lazy"
              scrolling="no"
            />
          </Col>
        ))}
      </Row>
    </FlexBox>
  );
};

export default InsightsView;
